import * as XLSX from "xlsx";
const { jsPDF } = require("jspdf");
const { autoTable } = require("jspdf-autotable");
const { format } = require("date-fns");

export function capitalizeFirstLetter(inputString) {
  return inputString.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}

function generateRandomFilename() {
  const randomString = Math.random().toString(36).substring(2, 8);
  const now = new Date();
  const timestamp = `${now.getFullYear()}${(now.getMonth() + 1)
    .toString()
    .padStart(
      2,
      "0"
    )}${now.getDate()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
  const filename = `export_${timestamp}_${randomString}`;
  return filename;
}

export const exportToExcel = (data, selectedFieldsMapping) => {
  // Map data to include only the selected fields with proper keys
  const filteredData = data.map((item) =>
    Object.keys(selectedFieldsMapping).reduce((obj, heading) => {
      const key = selectedFieldsMapping[heading]; // Get the key for the heading
      obj[heading] = item[key]; // Map the value to the heading
      return obj;
    }, {})
  );

  const ws = XLSX.utils.json_to_sheet(filteredData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([wbout], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${generateRandomFilename()}.xlsx`;
  a.click();
};

export const exportToPdf = (data) => {
  const doc = new jsPDF("l"); // Landscape mode
  const columns = [
    "Business Name",
    "Address",
    "Contact Name",
    "Company Number",
    "Company Name",
    "Phone",
    "Email",
    "Account Status",
    "Registration Date",
    "Deactivate Date",
    "Deactivate Comments",
    "Activate Date",
    "Activate Comments",
  ];

  const rows = data.map((item) => [
    item.businessName,
    item.businessAddress,
    item.contactName,
    item.companyNumber,
    item.companyName,
    item.businessPhoneNumber,
    item.email,
    item.isActive,
    item.createdAt ? format(new Date(item?.createdAt), "dd/MMM/yyyy") : "N/A",
    item.deactivateDate
      ? format(new Date(item.deactivateDate), "dd/MMM/yyyy")
      : "N/A",
    item.deactivateComments || "N/A",
    item.activateDate
      ? format(new Date(item.activateDate), "dd/MMM/yyyy")
      : "N/A",
    item.activateComments || "N/A",
  ]);

  doc.autoTable({
    head: [columns],
    body: rows,
    startY: 20,
    tableWidth: "auto", // Fits table to page width
    styles: {
      cellPadding: 3,
      fontSize: 7, // Reduced font size for better fit
      overflow: "linebreak",
    },
    headStyles: {
      fillColor: [41, 128, 185], // Custom header background color
      textColor: 255, // White text
    },
    didDrawPage: (data) => {
      // Header
      doc.setFontSize(14);
      doc.setTextColor(40);
      doc.text("Registered Business List", data.settings.margin.left, 15);

      // Footer
      const pageCount = doc.internal.getNumberOfPages();
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(10);
      doc.text(
        `Page ${doc.internal.getCurrentPageInfo().pageNumber} of ${pageCount}`,
        data.settings.margin.left,
        pageHeight - 10
      );
    },
  });

  // Save the PDF
  doc.save(`Registered_Business_List.pdf`);
};
