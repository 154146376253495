import React, { useEffect, useState } from "react";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MenuIcon from "@mui/icons-material/Menu";
import { BsPlus } from "react-icons/bs";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import "holderjs";
import MyFigureComponent from "./inc/MyFigureComponent";
import { useTranslation } from "react-i18next";
import BusinessDeleteConfirmation from "./modals/BusinessDeleteConfirmation";
import { connect } from "react-redux";
import AdvertiseABussiness from "./modals/AdvertiseABussiness";
import i18n from "i18next";
import AddPostCameraModal from "./modals/AddPostCameraModal";
import useApi from "../hooks/useApi";
import businessApi from "../api/business";
import promotionApi from "../api/promotion";
import {
  AppBar,
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  IActivateBusinessDto,
  IBusinessResponseDto,
  IDeactivateBusinessDto,
} from "../types/business";
import { useSnackbar } from "../context/snackbarContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IPromotionResponseDto, IResponseModel } from "../types/common";
import BusinessActivateConfirmation from "./modals/BusinessActivateConfirmation";
import { SelectedPromotion } from "../types/promotion";

const BusinessProfile = ({
  toggleBusinessDeleteConfirmModal,
  toggleBusinessActivateConfirmModal,
  toggleAdertiseBusinessModal,
}) => {
  const isLTR: boolean = i18n.language === "en";
  const { t } = useTranslation();

  const { showSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();

  const [businessDetails, setBusinessDetails] = useState<
    IBusinessResponseDto | undefined
  >(undefined);

  const [promotionsList, setPromotionsList] = useState<IPromotionResponseDto[]>(
    []
  );

  const [selectedPromotion, setSelectedPromotion] = useState<
    SelectedPromotion | undefined
  >(undefined);

  const [isReupload, setIsReupload] = useState<boolean>(false);

  const onDeactivateBusinessClick = () => {
    toggleBusinessDeleteConfirmModal(true);
  };

  const onActivateBusinessClick = () => {
    toggleBusinessActivateConfirmModal(true);
  };

  const handleOnAdvertiseBtnClick = () => {
    toggleAdertiseBusinessModal(true);
  };

  const getBusinessDetailsApi = useApi(businessApi.getBusinessDetails);
  const getPromotionsApi = useApi(promotionApi.getAllBusinessPromotions);
  const deactivatePromotionApi = useApi(promotionApi.deactivatePromotion);
  const deactivateBusinessApi = useApi(businessApi.deactivateBusiness);
  const activateBusinessApi = useApi(businessApi.activateBusiness);

  const handleConfirmDeactivateBusiness = async (
    payload: IDeactivateBusinessDto
  ) => {
    await deactivateBusinessApi.request(id, payload);
    if (!deactivateBusinessApi.loading) {
      navigate("/", { replace: true });
    }
  };

  const handleConfirmActivateBusiness = async (
    payload: IActivateBusinessDto
  ) => {
    await activateBusinessApi.request(id, payload);
  };

  const handleDeactivatePromotion = async (id: string) => {
    await deactivatePromotionApi.request(id);
  };

  const handleReuploadPromotion = async (
    promotionObj: IPromotionResponseDto
  ) => {
    setSelectedPromotion({ promotion: promotionObj, isReupload: true });
    toggleAdertiseBusinessModal(true);
  };

  const handleEditPromotion = async (promotionObj: IPromotionResponseDto) => {
    setSelectedPromotion({ promotion: promotionObj, isReupload: false });
    toggleAdertiseBusinessModal(true);
  };

  useEffect(() => {
    getBusinessDetailsApi.request(id);
    getPromotionsApi.request(id);
  }, []);

  useEffect(() => {
    const businessResponse: any = getBusinessDetailsApi?.data;
    if (businessResponse) {
      if (businessResponse?.data) {
        setBusinessDetails(businessResponse?.data);
      } else {
        showSnackbar(businessResponse?.message || "No business details found");
      }
    }

    const promotionResponse: any = getPromotionsApi?.data;
    if (promotionResponse) {
      if (promotionResponse?.data) {
        setPromotionsList(promotionResponse?.data);
      } else {
        showSnackbar(promotionResponse?.message || "No business details found");
      }
    }

    const activateResponse: any = activateBusinessApi?.data;
    if (activateResponse) {
      setBusinessDetails(activateResponse?.data);
      showSnackbar(activateResponse?.message || "No business details found");
    }
  }, [
    getBusinessDetailsApi?.data,
    getPromotionsApi?.data,
    activateBusinessApi?.data,
    deactivateBusinessApi?.data,
  ]);

  useEffect(() => {
    let response: IResponseModel = deactivatePromotionApi?.data;
    if (response) {
      if (response?.isSuccess) {
        showSnackbar(response?.message);
        getPromotionsApi.request(id);
      } else {
        showSnackbar(response?.message || "Something went wrong");
      }
    }
  }, [deactivatePromotionApi?.data]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const renderButtons = () => (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10,
      }}
    >
      <Button
        variant="contained"
        onClick={handleOnAdvertiseBtnClick}
        disabled={!businessDetails?.isActive}
        sx={{
          backgroundColor: "#FFFFFF",
          textTransform: "capitalize",
          color: "#FE0000",
          borderRadius: 10,
          "&:hover": {
            backgroundColor: "#c6c7c8",
          },
        }}
      >
        {t("lbl.advertise_a_benefit")}
      </Button>

      {businessDetails?.isActive ? (
        <Button
          variant="contained"
          onClick={onDeactivateBusinessClick}
          sx={{
            backgroundColor: "#FFFFFF",
            textTransform: "capitalize",
            color: "#FE0000",
            borderRadius: 10,
            "&:hover": {
              backgroundColor: "#c6c7c8",
            },
          }}
        >
          {t("lbl.deactivate_business")}
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={onActivateBusinessClick}
          sx={{
            backgroundColor: "#FFFFFF",
            textTransform: "capitalize",
            color: "#FE0000",
            borderRadius: 10,
            "&:hover": {
              backgroundColor: "#c6c7c8",
            },
          }}
        >
          {t("lbl.activate_business")}
        </Button>
      )}

      <Button
        sx={{
          backgroundColor: "#FFFFFF",
          textTransform: "capitalize",
          color: "#FE0000",
          borderRadius: 10,
          "&:hover": {
            backgroundColor: "#c6c7c8",
          },
        }}
        variant="contained"
        href={`/open-business/${id}`}
      >
        {t("lbl.edit_business")}
      </Button>

      <Button
        variant="contained"
        href="/home"
        sx={{
          backgroundColor: "#FFFFFF",
          textTransform: "capitalize",
          color: "#FE0000",
          borderRadius: 10,
          "&:hover": {
            backgroundColor: "#c6c7c8",
          },
        }}
      >
        {t("lbl.back")}
        &nbsp;
        {i18n.language === "en" ? (
          <ArrowForwardIosIcon
            sx={{
              fontSize: 14,
            }}
          />
        ) : (
          <ArrowBackIosIcon
            sx={{
              fontSize: 14,
            }}
          />
        )}
      </Button>
    </Box>
  );

  return (
    <>
      <BusinessDeleteConfirmation
        handleConfirmDeleteAction={handleConfirmDeactivateBusiness}
      />

      <BusinessActivateConfirmation
        handleConfirmActivateAction={handleConfirmActivateBusiness}
      />

      <AdvertiseABussiness
        businessDetails={businessDetails}
        getPromotionsApi={getPromotionsApi}
        selectedPromotion={selectedPromotion}
        setSelectedPromotion={setSelectedPromotion}
      />

      <AppBar position="static" style={{ backgroundColor: "red" }}>
        <Toolbar>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ textAlign: "center", flexGrow: 1 }}>
              {t("lbl.business_page")}
            </Typography>
            <IconButton edge="end" color="inherit" href="/home">
              {i18n.language === "en" ? (
                <ArrowForwardIosIcon />
              ) : (
                <ArrowBackIosIcon />
              )}
            </IconButton>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "left" }}>
              {t("lbl.business_page")}
            </Typography>
            {renderButtons()}
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            backgroundColor: "#fe0000 !important",
          },
        }}
        anchor={i18n.language === "en" ? "left" : "right"}
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Toolbar style={{ justifyContent: "center", margin: "15px" }}>
          <Link to={"/home"}>
            <img src="/feast-logo.png" width={120} height={50} />
          </Link>
        </Toolbar>
        <Divider />
        <List>
          <ListItem
            onClick={() => {
              handleOnAdvertiseBtnClick();
              toggleDrawer(false)(false);
            }}
            disabled={!businessDetails?.isActive}
            disablePadding
          >
            <ListItemButton
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#d10b11",
                },
              }}
            >
              {isLTR ? (
                <>
                  <ListItemText
                    sx={{ color: "white" }}
                    primary={t("lbl.advertise_a_benefit")}
                  />
                  <ListItemIcon>
                    <KeyboardArrowRightIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                </>
              ) : (
                <>
                  <ListItemIcon>
                    <KeyboardArrowLeftIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: "white" }}
                    primary={t("lbl.advertise_a_benefit")}
                  />
                </>
              )}
            </ListItemButton>
          </ListItem>
          <ListItem
            onClick={() => {
              if (businessDetails?.isActive) {
                onDeactivateBusinessClick();
              } else {
                onActivateBusinessClick();
              }
              toggleDrawer(false)(false);
            }}
            disablePadding
          >
            <ListItemButton
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#d10b11",
                },
              }}
            >
              {isLTR ? (
                <>
                  <ListItemText
                    sx={{ color: "white" }}
                    primary={
                      businessDetails?.isActive
                        ? t("lbl.deactivate_business")
                        : t("lbl.activate_business")
                    }
                  />
                  <ListItemIcon>
                    <KeyboardArrowRightIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                </>
              ) : (
                <>
                  <ListItemIcon>
                    <KeyboardArrowLeftIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: "white" }}
                    primary={
                      businessDetails?.isActive
                        ? t("lbl.deactivate_business")
                        : t("lbl.activate_business")
                    }
                  />
                </>
              )}
            </ListItemButton>
          </ListItem>
          <ListItem
            component="a"
            href={`/open-business/${id}`}
            onClick={toggleDrawer(false)}
            disablePadding
          >
            <ListItemButton
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#d10b11",
                },
              }}
            >
              {isLTR ? (
                <>
                  <ListItemText
                    sx={{ color: "white" }}
                    primary={t("lbl.edit_business")}
                  />
                  <ListItemIcon>
                    <KeyboardArrowRightIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                </>
              ) : (
                <>
                  <ListItemIcon>
                    <KeyboardArrowLeftIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: "white" }}
                    primary={t("lbl.edit_business")}
                  />
                </>
              )}
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      {businessDetails && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Box
            sx={{
              width: 150,
              height: 150,
              overflow: "hidden",
              borderRadius: "50%",
              mt: 2,
              mb: 2,
            }}
          >
            <Avatar
              src={businessDetails.businessLogoFile}
              alt={businessDetails.businessName}
              sx={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Typography variant="h4" align="center">
            {businessDetails.businessName}
          </Typography>
          <Typography variant="body1" align="center">
            {businessDetails.businessAddress}
          </Typography>

          <Grid container justifyContent="center" spacing={3} sx={{ mt: 3 }}>
            {promotionsList.map((promotion) => (
              <Grid item key={promotion.promotionId}>
                <MyFigureComponent
                  promotion={promotion}
                  handleReupload={handleReuploadPromotion}
                  handleEdit={handleEditPromotion}
                  handleDeactivatePromotion={handleDeactivatePromotion}
                />
              </Grid>
            ))}
          </Grid>
          <IconButton
            size="large"
            sx={{
              mt: 4,
              mb: 4,
              backgroundColor: "#FE0000",
              "&:hover": {
                backgroundColor: "#FE0000",
              },
            }}
            onClick={handleOnAdvertiseBtnClick}
            disabled={!businessDetails?.isActive}
          >
            <AddBoxOutlinedIcon
              sx={{
                color: "white",
                fontSize: 30,
              }}
            />
          </IconButton>
        </Box>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          getBusinessDetailsApi.loading ||
          getPromotionsApi.loading ||
          deactivateBusinessApi.loading ||
          activateBusinessApi.loading ||
          deactivatePromotionApi.loading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state) => ({});

const dispatchStateToProps = (dispatch) => ({
  toggleBusinessDeleteConfirmModal: (flag) => {
    dispatch({
      type: "TOGGLE_BUSINESS_DELETE_CONFIRMATION_MODAL",
      payload: flag,
    });
  },
  toggleBusinessActivateConfirmModal: (flag) => {
    dispatch({
      type: "TOGGLE_BUSINESS_ACTIVATE_CONFIRMATION_MODAL",
      payload: flag,
    });
  },
  toggleAdertiseBusinessModal: (flag) => {
    dispatch({
      type: "TOGGLE_ADVERTISE_A_BUSINESS_MODAL",
      payload: flag,
    });
  },
});

export default connect(mapStateToProps, dispatchStateToProps)(BusinessProfile);
