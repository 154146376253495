import React, { useEffect, useState } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { BsCamera } from "react-icons/bs";
import { TfiGallery } from "react-icons/tfi";
import { TfiPencil } from "react-icons/tfi";
import { BsFillPencilFill } from "react-icons/bs";
import { IBusinessRegRequest } from "../../types/business";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

interface IFileUploadProps {
  icon?: any;
  control?: Control<IBusinessRegRequest>;
  errors?: FieldErrors<IBusinessRegRequest>;
  showPreview?: boolean;
  fileSource?: string;
}

const FileUploadButton = ({
  icon,
  control,
  errors,
  showPreview = false,
  fileSource,
}: IFileUploadProps) => {
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState<any>(null);

  useEffect(() => {
    if (fileSource) {
      setSelectedFile(fileSource);
    }
  }, [fileSource]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      alert(t("Only image files are allowed!"));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {icon ? (
        <Button className="bg-transparent" style={{ border: 0 }}>
          <label htmlFor="fileInput">
            <TfiGallery color="white" />
          </label>
        </Button>
      ) : selectedFile ? (
        <Button className="bg-transparent" style={{ border: 0 }}>
          <label htmlFor="fileInput">
            <BsFillPencilFill color="black" style={{ float: "right" }} />
            <div
              style={{
                width: "100px",
                height: "80px",
                overflow: "hidden",
                borderRadius: "50%",
              }}
            >
              <img
                src={selectedFile}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </label>
        </Button>
      ) : (
        <Button
          variant="light"
          style={{ width: 100, height: 80, border: "1px solid #e5e5e5" }}
        >
          <label htmlFor="fileInput">
            <BsCamera style={{ fontSize: "2rem" }} />
          </label>
        </Button>
      )}

      <Form.Group>
        {control ? (
          <>
            <Controller
              name="businessLogoFile"
              control={control || undefined}
              defaultValue=""
              rules={{ required: t("lbl.business_logo_required") }}
              render={({ field: { value, onChange, ...field } }) => (
                <Form.Control
                  {...field}
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(event: any) => {
                    onChange(event.target.files[0]);

                    const file = event.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setSelectedFile(reader.result);
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                />
              )}
            />

            {errors?.businessLogoFile && (
              <p
                role="alert"
                style={{ color: "red", fontWeight: 500, marginTop: 5 }}
              >
                *{errors?.businessLogoFile?.message}
              </p>
            )}
          </>
        ) : (
          <FormControl
            id="fileInput"
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        )}
      </Form.Group>
    </Box>
  );
};

export default FileUploadButton;
